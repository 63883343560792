.first_message_pop {
  max-width: 70rem;
  z-index: 1000;
  background-color: #f3f3f3;
  padding: 2.5rem;
  transition: all .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #0000004d;
}

.first_message_pop img {
  width: 20rem;
  height: 20rem;
  margin-top: -1rem;
}

.first_message_pop div {
  text-align: center;
}

.first_message_pop h2 {
  color: #000;
  text-align: center;
}

.first_message_blur, .overlay {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 100;
  background-color: #00000080;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.modal {
  max-width: 40rem;
  margin-bottom: 20px;
}

.main_box_c {
  max-width: 70rem;
  z-index: 1000;
  background-color: #efefef;
  margin: 1rem;
  padding: 2.5rem;
  transition: all .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #0000004d;
}

.pag-box {
  margin-top: -.5rem;
}

.faraz_pagination button {
  margin-left: 2rem;
}

ul {
  list-style-type: none;
}

.preview__publisher, .preview__title {
  text-align: left;
  padding: .5rem;
}

.preview {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.pre_button {
  background: none;
  border: none;
  padding: 0;
}

.head_box {
  justify-content: space-between;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: -1rem;
  display: flex;
}

.btn--close-modal {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  margin-top: -1rem;
  font-family: inherit;
  font-size: 4rem;
}

.recipe__user-generated img {
  width: 3rem;
  padding: .1rem;
}

.preview__link {
  width: 43rem;
  margin: 0 0 0 -2rem;
}

.name_website {
  color: #000;
  margin-left: 2rem;
  font-size: 2rem;
  text-decoration: underline #bf2626;
}

.recipe {
  background-color: #000;
}

.nav__btn--bookmarks {
  margin-right: 1.5rem;
}

.search-placeholder ::placeholder {
  font-size: 1.5rem;
}

/*# sourceMappingURL=index.b8a67713.css.map */
